<div [id]="sceneId + '_' + hotspot?.slug" style="opacity: 0">
  <div
    *ngIf="hotspot?.args && hotspot?.args.label"
    class="label"
    [ngClass]="isShowInfo ? 'open' : ''"
    [ngStyle]="
      hotspot?.args.width && isShowInfo && { width: hotspot?.args.width + 'px' }
    "
  >
    <h3 [innerHTML]="hotspot.title"></h3>
  </div>
  <div class="hotspot-wrapper">
    <h2
      *ngIf="hotspot?.args && hotspot?.args.featured"
      [innerHTML]="hotspot?.title"
    ></h2>
    <div
      *ngIf="hotspot?.type === 'video'"
      (click)="openDialog()"
      class="hotspot"
      [ngClass]="hotspot?.args.featured ? 'featured' : ''"
      [attr.data-slug]="hotspot?.slug"
    >
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.20676 5.30313L1.80288 0.753121C1.1929 0.381246 0.288452 0.753121 0.288452 1.65V10.75C0.288452 11.5813 1.1298 12.0844 1.80288 11.6687L9.20676 7.11875C9.85876 6.70313 9.85876 5.71875 9.20676 5.30313Z"
        />
      </svg>
    </div>
    <div
      *ngIf="hotspot?.type === 'info'"
      (click)="toggleInfo()"
      class="hotspot"
      [attr.data-slug]="hotspot?.slug"
    >
      <svg
        width="12"
        height="12"
        viewBox="0 0 10 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.21875 10.4609C0.9375 10.4609 0.75 10.6719 0.75 10.9297V12.0312C0.75 12.3125 0.9375 12.5 1.21875 12.5H4.78125C5.03906 12.5 5.25 12.3125 5.25 12.0312V10.9297C5.25 10.6719 5.03906 10.4609 4.78125 10.4609H4.3125V5.46875C4.3125 5.21094 4.10156 5 3.84375 5H1.21875C0.9375 5 0.75 5.21094 0.75 5.46875V6.59375C0.75 6.85156 0.9375 7.0625 1.21875 7.0625H1.6875V10.4609H1.21875ZM3 0.5C2.0625 0.5 1.3125 1.27344 1.3125 2.1875C1.3125 3.125 2.0625 3.875 3 3.875C3.91406 3.875 4.6875 3.125 4.6875 2.1875C4.6875 1.27344 3.91406 0.5 3 0.5Z"
        />
      </svg>
    </div>
  </div>
</div>
